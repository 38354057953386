import { useState,useEffect } from "react"

const About = () =>{
    const slaytImgList=["/aboutImages/slaytImages/slayt1.jpg","/aboutImages/slaytImages/slayt2.jpg","/aboutImages/slaytImages/slayt3.jpg","/aboutImages/slaytImages/slayt4.jpg","/aboutImages/slaytImages/slayt5.jpg","/aboutImages/slaytImages/slayt6.jpg","/aboutImages/slaytImages/slayt7.jpg","/aboutImages/slaytImages/slayt8.jpg","/aboutImages/slaytImages/slayt9.jpg","/aboutImages/slaytImages/slayt10.jpg","/aboutImages/slaytImages/slayt11.jpg","/aboutImages/slaytImages/slayt12.jpg","/aboutImages/slaytImages/slayt13.jpg","/aboutImages/slaytImages/slayt14.jpg"]
    const [imgIndex,setImgIndex]=useState(0)
    const changeSlayt=(direction)=>{
        if (direction==="backward"){
            if(imgIndex===0){
                setImgIndex(slaytImgList.length-1)
            }else{
                setImgIndex(imgIndex-1)
            }
        }
        else if(direction==="forward"){
            if(imgIndex===slaytImgList.length-1){
                setImgIndex(0)
            }else{
                setImgIndex(imgIndex+1)
            }
        }
    }
    useEffect(()=>{
        const circles=document.querySelectorAll(".imgIndexCircle")
        circles.forEach((item)=>{
            if(item.id!==(imgIndex).toString()){
                item.style.backgroundColor="white"
            }else{
                item.style.backgroundColor="black"
            }
        })
    })
    /* 
    <div className="slayt">
                <div className="slaytContainer">
                    <div onClick={()=>changeSlayt("backward")}><i className="fa-solid fa-arrow-left slaytArrow"></i></div>
                    <div className="slaytImgContainer"><img src={slaytImgList[imgIndex]} alt=' '></img></div>
                    <div onClick={()=>changeSlayt("forward")}><i className="fa-solid fa-arrow-right slaytArrow"></i></div>
                </div>
                <div className="indexCircleContainer">
                    <div className="imgIndexCircle" id="0"></div>
                    <div className="imgIndexCircle" id="1"></div>
                    <div className="imgIndexCircle" id="2"></div>
                    <div className="imgIndexCircle" id="3"></div>
                    <div className="imgIndexCircle" id="4"></div>
                    <div className="imgIndexCircle" id="5"></div>
                    <div className="imgIndexCircle" id="6"></div>
                    <div className="imgIndexCircle" id="7"></div>
                    <div className="imgIndexCircle" id="8"></div>
                    <div className="imgIndexCircle" id="9"></div>
                    <div className="imgIndexCircle" id="10"></div>
                    <div className="imgIndexCircle" id="11"></div>
                    <div className="imgIndexCircle" id="12"></div>
                    <div className="imgIndexCircle" id="13"></div>
                </div>
            </div>
    */
    return(
        <div className="aboutContainer" id="about">
            <div className='header'>
                <div className='headerBackgroundImg'><img src="/menuImages/headerBackground.png" alt=" "></img></div>
                <h1 className='pageHeader'>~ Hakkımızda ~</h1>
            </div>
            <div className="imgAndDescription">
                <div className="placeImg"><img src='/homeImages/homeImage3.png' alt=''></img></div>
                <div className="description"><h1><div className="aboutLogo"><img src="/homeImages/valeriaLogo.png"alt='logo'></img></div>VALERİA Coffee - Dessert - Brekkie</h1>
                    <p>
                        Valeria'ya hoş geldiniz, lezzet dolu bir mutfak deneyimi için sıcak ve samimi bir mekan!
                         Kadıköy' ün merkezinde yer alan Valeria, sadece bir kafe değil; aynı zamanda lezzetlerin 
                         hayat bulduğu bir yerdir. Harika kahve, nefis tatlılar ve doyurucu kahvaltı seçenekleri 
                         konusundaki tutkumuz, yemek tutkunları ve kahve meraklıları için bir cennet yaratma 
                         konusundaki ilhamımızdır. Gününüze başlamak için mükemmel bir kahve, ihtiyaçlarınızı 
                         karşılayacak tatlı bir şeyler veya sabahınızı güçlendirecek sağlam bir kahvaltı arıyorsanız, 
                         Valeria sizi bekliyor. Taze pişmiş kruvasanlarımızın çeşitliliği, sıcak ve davetkar bir 
                         ortamla birleşerek her ziyareti unutulmaz kılıyor. Valeria'da her yudum ve ısırık, saf 
                         keyfin tadıdır. Sizleri Valeria'ya bekliyoruz, burada her ziyaret unutulmaz bir deneyim olacak.
                    </p>
                </div>
            </div>

            <div className="aboutAtRandomContainer">
                <img src="aboutImages/aboutAtRandom/aboutAtRandom1.jpg"></img>
                <img src="aboutImages/aboutAtRandom/aboutAtRandom2.jpg"></img>
                <img src="aboutImages/aboutAtRandom/aboutAtRandom3.jpg"></img>
                <img src="aboutImages/aboutAtRandom/aboutAtRandom5.jpg"></img>
            </div>
            
            <div className="infoWithNumbers">
                <div className="infoNumber">
                    <p className="number">100+</p>
                    <p className="infoHeader">Yiyecek ve İçecek Seçeneği</p>
                </div>
                <div className="infoNumber">
                    <p className="number">3148</p>
                    <p className="infoHeader">Günlük Müşteri</p>
                </div>
                <div className="infoNumber">
                    <p className="number">12</p>
                    <p className="infoHeader">Çalışan</p>
                </div>
            </div>
            
        </div>
    )
}
export default About;