import { useState,useEffect } from 'react'
import allFoods from '../listOfFoodsAndDrinks/foodList'
import allDrinks from "../listOfFoodsAndDrinks/drinkList";



const Menu = () =>{
    useEffect(()=>{
        var div=document.getElementById("0")
        div.classList.add("menuLinkClicked")
    },[])
    const [isDessertVisible,setDessertVisible]=useState(true)
    const [isCroissantVisible,setCroissantVisible]=useState(false)
    const [isTartineVisible,setTartineVisible]=useState(false)
    const [isSaladVisible,setSaladVisible]=useState(false)
    const [isBowlVisible,setBowlVisible]=useState(false)
    const [isHotDrinksVisible,setHotDrinksVisible]=useState(false)
    const [isColdDrinksVisible,setColdDrinksVisible]=useState(false)
    const [isFilterCoffeeVisible,setFilterCoffeeVisible]=useState(false)
    const [isteaVisible,setTeaVisible]=useState(false)
    const [isSurupVisible,setSurupVisible]=useState(false)
    const [isPaketUrunlerVisible,setPakerUrunlerVisible]=useState(false)
    const states=[[isDessertVisible,setDessertVisible],
        [isCroissantVisible,setCroissantVisible],
        [isTartineVisible,setTartineVisible],
        [isSaladVisible,setSaladVisible],
        [isBowlVisible,setBowlVisible],
        [isHotDrinksVisible,setHotDrinksVisible],
        [isColdDrinksVisible,setColdDrinksVisible],
        [isFilterCoffeeVisible,setFilterCoffeeVisible],
        [isteaVisible,setTeaVisible],
        [isSurupVisible,setSurupVisible],
        [isPaketUrunlerVisible,setPakerUrunlerVisible],
    ]
    const changeState=(id)=>{
        for(let index=0;index<states.length;index++){
            if(index===id){
                var div=document.getElementById(index.toString())
                div.classList.add("menuLinkClicked")
                states[index][1](true)
                //console.log(states[id],states[id][0])
            }else{
                div=document.getElementById(index.toString())
                div.classList.remove("menuLinkClicked")
                states[index][1](false)
            }
        }

    }

    /* 
    <div ><i className="fa-solid fa-utensils menuIcon"></i></div>
                <div className="menuLink">
                    <div className="imgWrapper">
                        <img src="../menuImages/foods.jpg" alt="foods"></img>
                        <div className="content"><Link to="/foods"  className='link'><h2><i className="fa-solid fa-utensils"></i>Yiyecekler</h2></Link></div>
                    </div>
                </div>
                <div className="menuLink">
                    <div className="imgWrapper">
                        <img src="../menuImages/drinks.jpg" alt="drinks"></img>
                        <div className="content"><Link to="/drinks"  className='link'><h2><i className="fa-solid fa-mug-hot"></i>İçecekler</h2></Link></div>
                    </div>
                </div>
                <div><i className="fa-solid fa-mug-hot menuIcon"></i></div>
    */
    return(
        <div className="menuContainer" id="menu">
            <div className='header'>
                <div className='headerBackgroundImg'><img src="/menuImages/headerBackground.png" alt=" "></img></div>
                <h1 className='pageHeader'>~ Menülerimiz ~</h1>
            </div>
            <div className="menuLinks">
                <div className="menuLink" id="0" onClick={()=>changeState(0)}>Tatlı</div>
                <div className="menuLink" id="1" onClick={()=>changeState(1)}>Kruvasan</div>
                <div className="menuLink" id="2" onClick={()=>changeState(2)}>Tartine-Ekmek Üstü</div>
                <div className="menuLink" id="3" onClick={()=>changeState(3)}>Salata</div>
                <div className="menuLink" id="4" onClick={()=>changeState(4)}>Bowl</div>
                <div className="menuLink" id="5" onClick={()=>changeState(5)}>Sıcak İçecekler</div>
                <div className="menuLink" id="6" onClick={()=>changeState(6)}>Soğuk İçecekler</div>
                <div className="menuLink" id="7" onClick={()=>changeState(7)}>Filtre Kahveler</div>
                <div className="menuLink" id="8" onClick={()=>changeState(8)}>Çaylar</div>
                <div className="menuLink" id="9" onClick={()=>changeState(9)}>Şrup</div>
                <div className="menuLink" id="10" onClick={()=>changeState(10)}>Paket Ürünler</div>
            </div>
            {
                isDessertVisible&&
                <div>
                    <i className="fa-solid fa-stroopwafel materialI"></i>
                    <div className='contentContainer'>
                    {    
                        allFoods[0].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>  
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isCroissantVisible&&
                <div>
                    <span className="material-icons materialI">bakery_dining</span>
                    <div className='contentContainer'>
                    {    
                        allFoods[1].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>     
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isTartineVisible&&
                <div>
                    <span className="material-icons materialI">breakfast_dining</span>
                    <div className='contentContainer'>
                    {    
                        allFoods[2].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>     
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isSaladVisible&&
                <div>
                    <span className="material-icons materialI">restaurant_menu</span>
                    <div className='contentContainer'>
                    {    
                        allFoods[3].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>     
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isBowlVisible&&
                <div>
                    <span className="material-icons materialI">rice_bowl</span>
                    <div className='contentContainer'>
                    {    
                        allFoods[4].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>   
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isHotDrinksVisible&&
                <div>
                    <span className="material-icons materialI">coffee</span>
                    <div className='contentContainer'>
                    {    
                        allDrinks[0].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>       
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isColdDrinksVisible&&
                <div>
                    <span className="material-icons materialI">local_cafe</span>                            
                    <div className='contentContainer'>
                    {    
                        allDrinks[1].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>       
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isFilterCoffeeVisible&&
                <div>
                <span className="material-icons materialI">coffee_maker</span>
                <div className='contentContainer'>
                {    
                    allDrinks[2].map((item)=>(
                        <div className='productName' key={item}>
                            <div className='menuPointer'></div>
                            <li className='dashedItem'>{item[0]}</li>       
                        </div>
                    ))
                }
                </div>
                </div>
            }
            {
                isteaVisible&&
                <div>
                    <span className="material-icons materialI">emoji_food_beverage</span>
                    <div className='contentContainer'>
                    {    
                        allDrinks[3].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>      
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isSurupVisible&&
                <div>
                    <span class="material-symbols-outlined materialI">water_bottle</span>
                    <div className='contentContainer'>
                    {    
                        allDrinks[4].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>        
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {
                isPaketUrunlerVisible&&
                <div>
                    <span class="material-symbols-outlined materialI">deployed_code</span>
                    <div className='contentContainer'>
                    {    
                        allDrinks[5].map((item)=>(
                            <div className='productName' key={item}>
                                <div className='menuPointer'></div>
                                <li className='dashedItem'>{item[0]}</li>        
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
        </div>
    )
}

export default Menu;