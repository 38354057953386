import {Link} from 'react-router-dom'

const Contact = () =>{
    return(
        <div className="contactContainerGeneral" id="contact">
            <div className='header'>
                <div className='headerBackgroundImg'><img src="/menuImages/headerBackground.png" alt=" "></img></div>
                <h1 className='pageHeader'>~ İletişim ~</h1>
            </div>
            <div className="contactContainer">
                <div className="contactInformations">
                    <div className="address contactInfo">
                        <i className="fa-solid fa-map-location-dot contactIcon"></i>
                        <h4>Caferağa, Moda Cd. No:75/C, 34710 Kadıköy/İstanbul</h4>
                    </div>
                    <div className="phone contactInfo">
                        <i className="fa-solid fa-phone contactIcon"></i>
                        <h4><a href="tel:+905374728836">+90 (537) 472 88 36</a></h4>
                    </div>
                    <div className="hours contactInfo">
                        <i className="fa-solid fa-clock contactIcon"></i>
                        <div><h4>Çalışma Saatleri</h4><p>Haftaiçi : 09:00 - 00:00</p><p>Haftasonu : 09:00 - 01:00</p></div>
                    </div>
                    <div className="hours"><Link to="https://www.instagram.com/valeriacoffeedessert/" target="_blank" rel="noopener noreferrer" className='contactInfo contactLink'><i className="fa-brands fa-instagram  contactIcon"></i><h4>İnstagram</h4></Link></div>
                </div>
                <div className='contactCatchword'>
                    <h2>~  Bize Ulaşın  ~</h2>
                    <p>Bize ulaşabileceğiniz iletişim noktamıza hoş geldiniz. Her türlü soru, görüş veya öneri için buradayız. Sizden haber almayı ve kafe deneyiminizi daha da özel kılmayı dört gözle bekliyoruz.</p>
                </div>
                <div className="map">
                    <iframe title="valeriaMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24092.507424163523!2d29.00208083715078!3d40.99103961158169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab90557c103f9%3A0x60731dd1c9e135e4!2sValeria%20Coffee!5e0!3m2!1str!2str!4v1691758878157!5m2!1str!2str" 
                    style={{width:"75vw" ,height:"50vh" ,style:"border:0" ,allowfullscreen:"" ,loading:"lazy" ,referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact;