import {useState} from 'react'
import {Link} from 'react-scroll'
import {scroller} from 'react-scroll'

const Navbar = () =>{
    const [isMenuOpen,setMenuOpen]=useState(false)
    const openMenu=()=>{
        const linkContainer=document.getElementById('linkContainer')
        const line1=document.getElementById('line1')
        const line2=document.getElementById('line2')
        const line3=document.getElementById('line3')
        if(!isMenuOpen){
            linkContainer.style.height='25vh'
            line1.style.transform='rotate(45deg) translateY(6px)'
            line2.style.opacity='0'
            line3.style.transform='rotate(-45deg) translateY(-6px)'
            setMenuOpen(true)
        }else{
            linkContainer.style.height='0vh'
            line1.style.transform='rotate(0deg) translateY(0px)'
            line2.style.opacity='1'
            line3.style.transform='rotate(0deg) translateY(0px)'
            setMenuOpen(false)
        }
    }
    const scrollToSection = (section) => {
        if(window.innerWidth<600){
            openMenu()
        }
        scroller.scrollTo(section, {
            spy:true ,
            smooth:true ,
            offset:-90 ,
            duration:1000
        });
      };
    return (
        <div className='navbarContainer' id='navbar'>
            <div className='valeriaLogo'>
                <Link to="/" className='logo' onClick={() => scrollToSection('home')}><div className='logoContainer'><img src="/homeImages/valeriaLogo.png"alt='logo'></img></div><p>VALERİA</p></Link>
            </div>
            <div className='navLinks'>
                <div className='menuHamburger' onClick={()=>openMenu()}>
                    <div className='line' id='line1'></div>
                    <div className='line' id='line2'></div>
                    <div className='line' id='line3'></div>
                </div>
                <ul id='linkContainer'>
                    <li><Link to="/" className='link' onClick={() => scrollToSection('home')}>Anasayfa</Link></li>
                    <li><Link to="about" className='link' onClick={() => scrollToSection('about')}>Hakkımızda</Link></li>
                    <li><Link to="menu" className='link' onClick={() => scrollToSection('menu')}>Menü</Link></li>
                    <li><Link to="contact" className='link' onClick={() => scrollToSection('contact')}>İletişim</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar;