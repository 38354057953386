import {Link} from 'react-scroll'
import {scroller} from 'react-scroll'



const Footer =()=>{
    const openMenu=()=>{
    }
    const scrollToSection = (section) => {
        if(window.innerWidth<600){
            openMenu()
        }
        scroller.scrollTo(section, {
            spy:true ,
            smooth:true ,
            offset:-90 ,
            duration:1000
        });
      };
    return(
        <div className="footerMainContainer">
            <div className='footerContainer'>
                <div className="footerLogo foot"><img src="/homeImages/valeriaLogoWhite.png"alt='logo'></img><div><p>VALERİA</p>Coffee & Dessert & Brekkie<p></p></div></div>
                <div className='footerLinks foot'>
                    <Link to="/" className='link' onClick={() => scrollToSection('home')}>- Anasayfa</Link>
                    <Link to="about" className='link' onClick={() => scrollToSection('about')}>- Hakkımızda</Link>
                    <Link to="menu" className='link' onClick={() => scrollToSection('menu')}>- Menü</Link>
                    <Link to="contact" className='link' onClick={() => scrollToSection('contact')}>- İletişim</Link>
                </div>
                <div className='footerWorkHours foot'>
                    <p>Çalışma Saatleri</p>
                    <p>Pazartesi : 09:00 - 00:00</p>
                    <p>Salı : 09:00 - 01:00</p>
                    <p><Link to="https://www.instagram.com/valeriacoffeedessert/" target="_blank" rel="noopener noreferrer" className='footerIcon'><i className="fa-brands fa-instagram  contactIcon"></i></Link></p>
                </div>
            </div>
            <p className='footerCoopyright'>Copyright © 2023 VALERİA - Tüm hakları saklıdır.</p>
        </div>
    )
}

export default Footer;