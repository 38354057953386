const drinkListHot=[
    ["Kitkat Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/muzluSıcakÇikolata.jpg"],
    ["Karbon Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/muzluSıcakÇikolata.jpg"],
    ["Lotus Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/muzluSıcakÇikolata.jpg"],
    ["Pink Latte -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/hotDrinks/singleEspresso.jpg"],
    ["Lavanta Latte -----------------------------------------------------------------------------------------------------",75.00,"/drinkImages/hotDrinks/matchaLatte.jpg"],
    ["Single Expresso -----------------------------------------------------------------------------------------------------",75.00,"/drinkImages/hotDrinks/goldenLatte.jpg"],
    ["Espresso Machiato -----------------------------------------------------------------------------------------------------",45.00,"/drinkImages/hotDrinks/espressoMachiato.jpg"],
    ["Marshmallow Latte -----------------------------------------------------------------------------------------------------",62.00,"/drinkImages/hotDrinks/americano.jpg"],
    ["Matcha Latte -----------------------------------------------------------------------------------------------------",68.00,"/drinkImages/hotDrinks/americanoSütlü.jpg"],
    ["Golden Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/cafeLatte.jpg"],
    ["Expresso Macchiato -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/latteMacchiato.jpg"],
    ["Americano -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/cortado.jpg"],
    ["Cafe Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/cappucino.jpg"],
    ["Latte Macchiato -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/flatWhite.jpg"],
    ["Cortado -----------------------------------------------------------------------------------------------------",75.00,"/drinkImages/hotDrinks/cafeMocha.jpg"],
    ["Cappucino -----------------------------------------------------------------------------------------------------",75.00,"/drinkImages/hotDrinks/whiteChocolateMocha.jpg"],
    ["Popcorn Latte -----------------------------------------------------------------------------------------------------",75.00,"/drinkImages/hotDrinks/caramelMacchiato.jpg"],
    ["Flat White -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/hotDrinks/sıcakÇikolata.jpg"],
    ["Toffee Nut Latte -----------------------------------------------------------------------------------------------------",65.00,"/drinkImages/hotDrinks/chaiTeaLatte.jpg"],
    ["Cafe Mocha -----------------------------------------------------------------------------------------------------",22.00,"/drinkImages/hotDrinks/sütSeçenekleriBadem.jpg"],
    ["White Chocolate Mocha -----------------------------------------------------------------------------------------------------",22.00,"/drinkImages/hotDrinks/sütSeçenekleriYulaf.jpg"],
    ["Caramel Macchiato -----------------------------------------------------------------------------------------------------",50.00,"/drinkImages/hotDrinks/türkKahvesi.jpg"],
    ["Sıcak Çikolata -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/doubleTürkKahvesi.jpg"],
    ["Chai Latte -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/doubleTürkKahvesi.jpg"],
    ["Türk Kahvesi -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/doubleTürkKahvesi.jpg"],
    ["Double Türk Kahvesi -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/doubleTürkKahvesi.jpg"],
    ["Salep -----------------------------------------------------------------------------------------------------",60.00,"/drinkImages/hotDrinks/doubleTürkKahvesi.jpg"]
]
const drinkListCold=[
    ["Affogato -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/purpleLight.jpg"],
    ["Blue Matcha Latte -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/blackMulberry.jpg"],
    ["Mavi Çilekli Limonata -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/barbieIce.jpg"],
    ["Cold Brew Latte -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/coldDrinks/coldBrewLatte.jpg"],
    ["Cold Brew -----------------------------------------------------------------------------------------------------",65.00,"/drinkImages/coldDrinks/coldBrew.jpg"],
    ["İce Cortado -----------------------------------------------------------------------------------------------------",65.00,"/drinkImages/coldDrinks/IceCortado.jpg"],
    ["İce Matcha Latte -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/IceMatchaLatte.jpg"],
    ["Iced Americano -----------------------------------------------------------------------------------------------------",68.00,"/drinkImages/coldDrinks/icedAmericano.jpg"],
    ["Iced Latte -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/icedMocha.jpg"],
    ["Iced Mocha -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/icedWhiteMocha.jpg"],
    ["Iced White Mocha -----------------------------------------------------------------------------------------------------",80.00,"/drinkImages/coldDrinks/icedCaramelMacchiato.jpg"],
    ["Iced Caramel Macchiato -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/coldDrinks/icedChaiTeaLatte.jpg"],
    ["Limonata -----------------------------------------------------------------------------------------------------",62.00,"/drinkImages/coldDrinks/limonata.jpg"],
    ["Sıkma Portakal Suyu -----------------------------------------------------------------------------------------------------",70.00,"/drinkImages/coldDrinks/sıkmaPortakalSuyu.jpg"],
    ["Churcill -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/coldDrinks/churcill.jpg"],
    ["Soda -----------------------------------------------------------------------------------------------------",30.00,"/drinkImages/coldDrinks/soda.jpg"],
    ["Iced Chai Tea Latte -----------------------------------------------------------------------------------------------------",20.00,"/drinkImages/coldDrinks/su.jpg"],
    ["Su -----------------------------------------------------------------------------------------------------",20.00,"/drinkImages/coldDrinks/su.jpg"]
]
const drinkListFilterCoffee=[
    ["Filtre Kahve -----------------------------------------------------------------------------------------------------",58.00,"/drinkImages/filterCoffee/filtreKahve.jpg"],
    ["Filtre Kahve Sütlü -----------------------------------------------------------------------------------------------------",64.00,"/drinkImages/filterCoffee/filtreKahveSütlü.jpg"],
    ["V60 -----------------------------------------------------------------------------------------------------",90.00,"/drinkImages/filterCoffee/v60.jpg"],
    ["Chemex -----------------------------------------------------------------------------------------------------",90.00,"/drinkImages/filterCoffee/chemex.jpg"]
]
const drinkListTea=[
    ["Demleme Çay -----------------------------------------------------------------------------------------------------",26.00,"/drinkImages/tea/demlemeÇay.jpg"],
    ["Fincan Çay -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Last Unicorn -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Ihlamur -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Red Berry -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Yaseminli Yeşil Çay -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Tropikal Meyve Çayı -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"]
]
const drinkListSurup=[
    ["Vanilya -----------------------------------------------------------------------------------------------------",26.00,"/drinkImages/tea/demlemeÇay.jpg"],
    ["Karamel -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Rose -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Fındık -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Bubblegum -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["İrish  -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"]
]
const drinkListPaketÜrünler=[
    ["100 gr Red Berry Çay -----------------------------------------------------------------------------------------------------",26.00,"/drinkImages/tea/demlemeÇay.jpg"],
    ["100 gr Ihlamur -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Yaseminli Yeşil Çay -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["Tropikal Meyve Çay -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["250 gr Rwanda Filtre Kahve -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["250 gr Honduras Filtre Kahve  -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["250 gr Guetemala Filtre Kahve  -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"],
    ["250 gr Costa Rica Filtre Kahve  -----------------------------------------------------------------------------------------------------",40.00,"/drinkImages/tea/demlemeÇayFincan.jpg"]
]

const allDrinks=[]
allDrinks.push(drinkListHot)
allDrinks.push(drinkListCold)
allDrinks.push(drinkListFilterCoffee)
allDrinks.push(drinkListTea)
allDrinks.push(drinkListSurup)
allDrinks.push(drinkListPaketÜrünler)

export default allDrinks;