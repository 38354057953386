import { useEffect,useState } from "react";

const Home = () =>{
    const backgroundHomeImages=["/homeImages/homeImage1.jpg","/homeImages/homeImage2.jpg","/homeImages/homeImage3.png"]

    window.addEventListener('scroll',()=>{
        const scrollPosition=window.scrollY;
        const triggerPoint=50;
        const navbar=document.getElementById("navbar")
        if(navbar!==null && scrollPosition!==null){
            if(scrollPosition>triggerPoint){
                navbar.style.backgroundColor="rgba(235, 221, 199, 1)"
            }else{
                navbar.style.backgroundColor="rgba(235, 221, 199, 0)"
            }
        }
    })

    const [homeImgIndex,setHomeImgIndex]=useState(0)
    useEffect(()=>{
        setTimeout(()=>{
            if(homeImgIndex>1){
                setHomeImgIndex(0)
            }else{
                setHomeImgIndex(homeImgIndex+1)
            }
        },5000)
    },[homeImgIndex])


    return(
        <div className="homeContainer" id="home">
            <div className=" ">
                <div className="backHomeImg" >
                    <img id="currentImg" src={backgroundHomeImages[homeImgIndex]} alt=""></img>
                    <div className="overlayText">
                        <div className="overlayImg"><img src="/homeImages/valeriaLogo.png"alt='logo'></img></div>
                        <h1>VALERİA</h1>
                        <h4>COFFEE, DESSERT & BREKKIE</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;