import Navbar from "../components/Navbar";
import About from './About';
import Menu from './Menu';
import Home from './Home';
import Contact from "./Contact";
import Footer from "../components/Footer";

const AllPages = () =>{
    return(
        <div className="homeContainer" id="home">
            <Navbar></Navbar>
            <Home></Home>
            <About></About>
            <Menu></Menu>
            <Contact></Contact>
            <Footer></Footer>
        </div>
    )
}

export default AllPages;