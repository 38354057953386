const foodListDessert=[
    ["Creme Brulee Meyveli -----------------------------------------------------------------------------------------------------",130.00,"/foodImages/dessert/çilekliCremeBrulee.jpg"],
    ["Creme Brulee Çikolatalı -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/dessert/cremeBruleeÇikolatalı.jpg"],
    ["Dondurmalı Creme Brulee -----------------------------------------------------------------------------------------------------",150.00,"/foodImages/dessert/dondurmalıCremeBrulee.jpg"],
    ["Soğuk Sweet Bowl -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/dessert/soğukSweetBowl.jpg"],
    ["Dondurmalı Cookie Cake -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/dessert/dondurmalıCookieCake.jpg"],
    ["Creme Brulee Meyveli -----------------------------------------------------------------------------------------------------",150.00,"/foodImages/dessert/cremeBrulieMeyveli.jpg"],
    ["Lotus Cup -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/lotusCup.jpg"],
    ["Creme Brulee -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/cremeBrulee.jpg"],
    ["Kinder Cheescake -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/kinderCheescake.jpg"],
    ["Dark Cream -----------------------------------------------------------------------------------------------------",110.00,"/foodImages/dessert/darkCream.jpg"],
    ["Dondurmalı Beyaz Çikolatalı Brownie -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/dessert/dondurmalıBeyazÇikolatalı.jpg"],
    ["Sweet Bowl -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/sweetBowl.jpg"],
    ["White Tart -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/whiteTart.jpg"],
    ["Cookie Cake -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/cookieCake.jpg"],
    ["Profiterol -----------------------------------------------------------------------------------------------------",110.00,"/foodImages/dessert/profiterol.jpg"],
    ["Tiramisu -----------------------------------------------------------------------------------------------------",110.00,"/foodImages/dessert/tiramisu.jpg"],
    ["Beyaz Çikolatalı Brownie -----------------------------------------------------------------------------------------------------",120.00,"/foodImages/dessert/beyazÇikolatalıBrowni.jpg"],
    ["Sufle -----------------------------------------------------------------------------------------------------",110.00,"/foodImages/dessert/sufle.jpg"],
    ["Kremalı Meyveli Kruvasan -----------------------------------------------------------------------------------------------------",28.00,"/foodImages/dessert/ekstraMeyve.jpg"],
    ["Dondurmalı Meyveli Kruvasan -----------------------------------------------------------------------------------------------------",24.00,"/foodImages/dessert/ekstraÇikolata.jpg"],
    ["Çikolatalı Kruvasan -----------------------------------------------------------------------------------------------------",24.00,"/foodImages/dessert/ekstraÇikolata.jpg"],
    ["Fıstık Topic -----------------------------------------------------------------------------------------------------",24.00,"/foodImages/dessert/ekstraÇikolata.jpg"],
    ["İbiza -----------------------------------------------------------------------------------------------------",24.00,"/foodImages/dessert/ekstraÇikolata.jpg"],
    ["Baklava Magnolia -----------------------------------------------------------------------------------------------------",24.00,"/foodImages/dessert/ekstraÇikolata.jpg"]
]
const foodListCroissant=[
    ["Kruvasan Sandwich -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/croissant/kruvasanSandwich.jpg"],
    ["Chicken Kruvasan Burger -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/croissant/kruvasanSandwich.jpg"],
    ["Olive Kruvasan -----------------------------------------------------------------------------------------------------",190.00,"/foodImages/croissant/oliveKruvasan.jpg"],
    ["Dana Bacon Kruvasan -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/croissant/danaBaconKruvasan.jpg"],
    ["Tereyağlı Kruvasan -----------------------------------------------------------------------------------------------------",70.00,"/foodImages/croissant/tereyağlıKruvasan.jpg"],
    ["Mozarella Domates Kruvasan -----------------------------------------------------------------------------------------------------",190.00,"/foodImages/croissant/mozarellaDomatesKruvasan.jpg"],
    ["Hellim Sucuklu Kruvasan -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/croissant/hellimSucukluKruvasan.jpg"],
    ["Avokadolu Füme Etli Kruvasan -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/croissant/avokadoluFümeEtliKruvasan.jpg"],
    ["Frankfurt Sosisli Kruvasan -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/croissant/frankfurtSosisliKruvasan.jpg"]
]
const foodListTartine=[
    ["Burrata Tartine -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/burrataTartine.jpg"],
    ["Good Morning Valeria -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/burrataTartine.jpg"],
    ["Spring Tartine -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/whiteTartine.jpg"],
    ["English Breakfast -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/whiteTartine.jpg"],
    ["Valeria Kahvaltı -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/valeriaKahvaltı.jpg"],
    ["Brulee French Toast -----------------------------------------------------------------------------------------------------",150.00,"/foodImages/tartine/bruleeFrenchToast.jpg"],
    ["Avokado Tartine -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/avokadotartine.jpg"],
    ["Pancake Tabağı -----------------------------------------------------------------------------------------------------",160.00,"/foodImages/tartine/çılbırTartine.jpg"],
    ["Girit Mantarlı Tartine -----------------------------------------------------------------------------------------------------",200.00,"/foodImages/tartine/giritMantarlıtartine.jpg"]
]
const foodListSalad=[
    ["Tavuklu Salata -----------------------------------------------------------------------------------------------------",170.00,"/foodImages/salad/tavukluSalata.jpg"],
    ["Ton Balıklı Salata -----------------------------------------------------------------------------------------------------",140.00,"/foodImages/salad/tonBalıklıSalata.jpg"],
    ["Avokadolu Salata -----------------------------------------------------------------------------------------------------",150.00,"/foodImages/salad/avokadoluSalata.jpg"]
]
const foodListBowl=[
    ["Yulaf Bowl -----------------------------------------------------------------------------------------------------",160.00,"/foodImages/bowl/yulafBowl.jpg"],
    ["Smoothie Bowl -----------------------------------------------------------------------------------------------------",180.00,"/foodImages/bowl/smoothieBowl.jpg"],
    ["Kırmızı Bowl -----------------------------------------------------------------------------------------------------",170.00,"/foodImages/bowl/kırmızıBowl.jpg"]
]

const allFoods=[]
allFoods.push(foodListDessert)
allFoods.push(foodListCroissant)
allFoods.push(foodListTartine)
allFoods.push(foodListSalad)
allFoods.push(foodListBowl)

export default allFoods;